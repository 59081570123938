/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo';
import Section from '../components/Section/Section';
import Post from '../components/Blog/Post';
import Paginator from '../components/Paginator/Paginator';
import AuthorBox from '../components/Blog/AuthorBox';

export const pageQuery = graphql`
  query BlogAuthorByIdQuery($id: String!, $skip: Int!, $limit: Int!) {
    wpUser(id: { eq: $id }) {
      uri
      name
      description
      avatar {
        url
      }
      seo {
        title
        metaDesc
      }
    }

    allWpPost(
      filter: { author: { node: { id: { eq: $id } } } }
      sort: { fields: [date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt

        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1110)
              }
            }
          }
        }

        categories {
          nodes {
            name
            uri
          }
        }
      }
    }
  }
`;

const BlogAuthorPage = ({ data, pageContext }) => {
  const author = data.wpUser;
  const posts = data.allWpPost.nodes;

  return (
    <Layout>
      <Seo title={author.seo.title} description={author.seo.metaDesc} />
      <Section>
        <Container>
          <Row className="c-blog-header">
            <Col>
              <h1 className="text-center m-0">Author: {author.name}</h1>
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col lg="7">
              {posts.map((post) => (
                <Post
                  key={post.uri}
                  uri={post.uri}
                  date={post.date}
                  author={author}
                  title={post.title}
                  featuredImage={post.featuredImage}
                  excerpt={post.excerpt}
                  categories={post.categories.nodes}
                />
              ))}
              <Row className="justify-content-center">
                <Col xs="auto">
                  <Paginator pageContext={pageContext} path={author.uri} />
                </Col>
              </Row>
            </Col>
            <Col lg="4">
              <AuthorBox
                image={author.avatar.url}
                name={author.name}
                uri={author.uri}
                description={author.description}
              />
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default BlogAuthorPage;
